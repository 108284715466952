const initialState = {
  open: false,
};

const drawer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER": {
      return { ...state, open: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default drawer;
